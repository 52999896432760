/* eslint no-eval:0 */
// importación
import React, { useState } from 'react'
import words from 'lodash.words'
import Functions from './components/Functions'
import Numbers from './components/Numbers'
import MathOperations from './components/MathOperations'
import Result from './components/Result'
import './App.css'

// generación de la función del componente
// Función Flecha o Arrow Function
const App = () => {
    //const arrayTextoFuncionModificaTexto = useState("")
    // Array Destructuring
    const [stack, setStack] = useState("")
 
    const items = words(stack, /[^-^+^*^/]+/g)
    // arrayTextoFuncionModificaTexto => ["", funcion]
    
    // 1ra posición: valor (que inicialmente es el valor por defecto)
    // const texto = arrayTextoFuncionModificaTexto [0]

    // 2da posición: función que me va a permitir modificar el valor por defecto       
    // const funcionModificaTexto = arrayTextoFuncionModificaTexto [1]

    // Es similar a un if pero en una sola linea
    // (esVerdadero) ? (resultadoPorVerdadero) : (resultadoPorFalso)
    const value = items.length > 0 ? items[items.length-1] : "0" 
  
    return (
    <main className='react-calculator'>
        <Result value={value}/>
        <Numbers onClickNumber={number => {
           setStack(`${stack}${number}`)
        }}/>
        <Functions 
           onContentClear={() => {
               setStack("")
            }}
           onDelete={() => {
              if (stack.length>0){            
                const newStack = stack.substring(0, stack.length - 1)
                setStack(newStack)
              }
            }}
        />
        <MathOperations 
            onClickOperation={operation => {
            setStack(`${stack}${operation}`)
            }}
            onClickEqual={equal => {
             setStack(eval(stack).toString())
            }} />
    </main>)
}

// exportación
export default App